import apiClient from './apiClient';

export const getManagers = (params = {}) => {
  return apiClient.get(`/managers`, {
    params: params,
  });
};

export const getManager = (id) => {
  return apiClient.get(`/managers/${id}`);
};

export const saveManager = (data) => {
  return apiClient.post("/managers", data);
};

export const updateManager = (data, id) => {
  return apiClient.put(`/managers/${id}`, data);
};

export const deleteManager = (id) => {
  return apiClient.delete(`/managers/${id}`);
};
