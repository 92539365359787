import apiClient from './apiClient';

export const getBets = (params = {}) => {
  return apiClient.get(`/bets`, {
    params: params,
  });
};

export const getBet = (id) => {
  return apiClient.get(`/bets/${id}`);
};

export const saveBet = (data) => {
  return apiClient.post("/bets", data);
};

export const updateBet = (data, id) => {
  return apiClient.put(`/bets/${id}`, data);
};

export const deleteBet = (id) => {
  return apiClient.delete(`/bets/${id}`);
};
