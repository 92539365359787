import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBettors } from '../../services/bettorService';

// Async thunk for fetch accounts
export const fetchBettors = createAsyncThunk('account/fetchBettors', async (_, thunkAPI) => {
    try {
        const state = thunkAPI.getState().bettor;
        const { filters, page, sort_by, sort_order, per_page } = state;
        const params = { ...filters, page, sort_by, sort_order, per_page };
        const response = await getBettors(params);
        const data = response.data;
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

// Initial state
const initialState = {
    bettors: [],
    pagination: {},
    loading: false,
    error: null,
    filters: {
        name: '',
        email: '',
    },
    sort_by: 'id',
    sort_order: 'desc',
    page: 1,
    per_page: 10 
};

const bettorSlice = createSlice({
    name: 'bettor',
    initialState,
    reducers: {
        refresh(state) {
            Object.assign(state, initialState);
        },
        setFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
            state.page = 1;
        },
        setSort(state, action) {
            const sort = action.payload;
            const [sort_by, sort_order] = sort.split('|');
            state.sort_by = sort_by;
            state.sort_order = sort_order;
        },
        setPage(state, action) {
            state.page = action.payload;
        },   
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBettors.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBettors.fulfilled, (state, action) => {  
                state.bettors = action.payload.data;
                state.pagination = {
                    current_page: action.payload.pagination.current_page,
                    last_page: action.payload.pagination.last_page,
                    per_page: action.payload.pagination.per_page,
                    total: action.payload.pagination.total,
                };
                state.loading = false;
            })
            .addCase(fetchBettors.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { refresh, setFilters, setSort, setPage } = bettorSlice.actions;
export default bettorSlice.reducer;


