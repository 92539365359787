import "./App.css";
import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// Components
import withClearCache from './ClearCache'
import Layout from "./pages/Layout";
import Authorization from "./components/Authorization";

// Pages
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Admin from "./pages/Admin";

import Dashboard from "./pages/protected/Dashboard";
import DailyFigures from "./pages/protected/dailyfigures/DailyFigures";
import Figure from "./pages/protected/dailyfigures/Figure";
import PendingFigures from "./pages/protected/dailyfigures/PendingFigures";
import AddFigure from "./pages/protected/dailyfigures/AddFigure";
import EditFigure from "./pages/protected/dailyfigures/EditFigure";
import Handicappers from "./pages/protected/handicappers/Handicappers";
import Handicapper from "./pages/protected/handicappers/Handicapper";
import HandicapperInsights from "./pages/protected/handicappers/HandicapperInsights";
import Accounts from "./pages/protected/accounts/Accounts";

import Managers from "./pages/protected/managers/Managers";
import AddManager from "./pages/protected/managers/AddManager";
import EditManager from "./pages/protected/managers/EditManager";
import Bettors from "./pages/protected/bettors/Bettors";
import AddBettor from "./pages/protected/bettors/AddBettor";
import EditBettor from "./pages/protected/bettors/EditBettor";
import Agents from "./pages/protected/agents/Agents";
import AddAgent from "./pages/protected/agents/AddAgent";
import EditAgent from "./pages/protected/agents/EditAgent";
import Bets from "./pages/protected/bets/Bets";
import AddBet from "./pages/protected/bets/AddBet";
import EditBet from "./pages/protected/bets/EditBet";

// RBAC
import { AbilityContext } from './rbac/Can'
import ability from './rbac/Ability'



const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  
  useEffect(() => {
    const abortController = new AbortController();
    return () => abortController.abort();
  }, []);

  return (
    <AbilityContext.Provider value={ability}>
      <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="admin" element={<Admin />}>

          <Route path="dashboard" element={<Authorization action="view" subject="dashboard"><Dashboard /></Authorization>} />

          <Route path="daily-figures" element={<Authorization action="view" subject="daily-figures"><DailyFigures /></Authorization>} />
          <Route path="daily-figures/pending" element={<Authorization action="view" subject="daily-figures"><PendingFigures /></Authorization>} />
          <Route path="daily-figure/add" element={<Authorization action="create" subject="daily-figures"><AddFigure /></Authorization>} />
          <Route path="daily-figures/edit/:id" element={<Authorization action="update" subject="daily-figures"><EditFigure /></Authorization>} />
          <Route path="daily-figure/:id" element={<Authorization action="view" subject="daily-figures"><Figure /></Authorization>} />
          
          <Route path="handicappers" element={<Authorization action="view" subject="handicappers"><Handicappers /></Authorization>} />
          <Route path="handicappers/insights" element={<Authorization action="view" subject="handicappers"><HandicapperInsights /></Authorization>} />
          <Route path="handicappers/:id" element={<Authorization action="view" subject="handicappers"><Handicapper /></Authorization>} />
          
          <Route path="accounts" element={<Authorization action="view" subject="accounts"><Accounts /></Authorization>} />
          
          
          <Route path="managers" element={<Authorization action="view" subject="managers"><Managers /></Authorization>} />
          <Route path="managers/add" element={<Authorization action="create" subject="managers"><AddManager /></Authorization>} />
          <Route path="managers/edit/:id" element={<Authorization action="update" subject="managers"><EditManager /></Authorization>} />

          <Route path="bettors" element={<Authorization action="view" subject="bettors"><Bettors /></Authorization>} />
          <Route path="bettors/add" element={<Authorization action="create" subject="bettors"><AddBettor /></Authorization>} />
          <Route path="bettors/edit/:id" element={<Authorization action="update" subject="bettors"><EditBettor /></Authorization>} />

          <Route path="agents" element={<Authorization action="view" subject="agents"><Agents /></Authorization>} />
          <Route path="agents/add" element={<Authorization action="create" subject="agents"><AddAgent /></Authorization>} />
          <Route path="agents/edit/:id" element={<Authorization action="update" subject="agents"><EditAgent /></Authorization>} />

          <Route path="bets" element={<Authorization action="view" subject="bets"><Bets /></Authorization>} />
          <Route path="bets/add" element={<Authorization action="create" subject="bets"><AddBet /></Authorization>} />
          <Route path="bets/edit/:id" element={<Authorization action="update" subject="bets"><EditBet /></Authorization>} />


        </Route>
        <Route path="" element={<Navigate to="/login" />} />
      </Route>
    </Routes>
    </AbilityContext.Provider>
    
  );
}

export default App;
