import { Link } from "react-router-dom";

const Error = ({ code, message }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-gray-800">
      <h1 className="text-9xl font-bold text-center">{code}</h1>
      <p className="text-4xl mt-4 text-center">{message}</p>
      <Link
        to="/admin/dashboard"
        className="mt-10 px-8 py-3 bg-red-600 text-white text-lg rounded-lg shadow-md hover:bg-red-700 transition"
      >
        Go to Dashboard
      </Link>
    </div>
  );
};

export const BadRequestError = () => <Error code="400" message="Bad Request" />;
export const UnauthorizedError = () => <Error code="401" message="Unauthorized Access" />;
export const NotFoundError = () => <Error code="404" message="Page Not Found" />;


export default Error;

