import apiClient from './apiClient';

export const getAgents = (params = {}) => {
  return apiClient.get(`/agents`, {
    params: params,
  });
};

export const getAgent = (id) => {
  return apiClient.get(`/agents/${id}`);
};

export const saveAgent = (data) => {
  return apiClient.post("/agents", data);
};

export const updateAgent = (data, id) => {
  return apiClient.put(`/agents/${id}`, data);
};

export const deleteAgent = (id) => {
  return apiClient.delete(`/agents/${id}`);
};

export const getAgentsList = () => {
  return apiClient.get("/agents/list");
};
