import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPenToSquare,
  faEye,
  faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";
// Components
import TableLoader from "../../../components/TableLoader";
import DropDown from "../../../components/form/DropDown";
import Pagination from "../../../components/Pagination";
import DateRangeCalendar from "../../../components/form/DateRangeCalendar";
import CalendarButton from "../../../components/form/CalendarButton";
// Store and Services
import {
  getDailyFigureWebsites,
  getDailyFigureAccounts,
  updateDailyFigureField
} from "../../../services/dailyFigureService";
import { fetchDailyFigures, refresh, setFilters, setPage, setWeek, setDateRange } from "../../../redux/slices/dailyFigureSlice";
import { getActiveHandicappers } from "../../../services/handicapperService";
// Helpers
import {
  formatResult,
  getStatuses,
  getWeeks,
  resultColor,
} from "../../../utils/helpers";

export default function DailyFigures() {
  const dispatch = useDispatch();
  const {dailyFigures, pagination, loading, filters, page} = useSelector((state) => state.dailyFigure);
  const [websites, setWebsites] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [handicappers, setHandicappers] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [statuses, setStatuses] = useState([]);
  
  useEffect(() => {

    setWeeks(getWeeks());
    setStatuses(getStatuses());

    getDailyFigureWebsites().then((res) => {
      setWebsites(res.data.data);
    });

    getDailyFigureAccounts().then((res) => {
      setAccounts(res.data.data);
    });

    getActiveHandicappers().then((res) => {
      setHandicappers(res.data);
    });

  }, []);

  useEffect(() => {
    dispatch(fetchDailyFigures());
  }, [dispatch, filters, page]);


  const handleFieldChange = async (e, id) => {
    try {
      const data = { [e.target.name]: e.target.value };
      const res = await updateDailyFigureField(data, id);
  
      if (res?.data?.status === "success") {
        toast.success(res?.data?.message);
      }
      
      return true;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Validation errors
        const validationErrors = error.response.data.errors;
        for (const [field, messages] of Object.entries(validationErrors)) {
          // Show error messages for each field
          messages.forEach(message => toast.error(`${message}`));
        }
      } else {
        // Other errors
        toast.error('An error occurred. Please try again.');
      }
      return false; // Optional, in case you want to return false on failure
    }
  };

  const formik = useFormik({
    initialValues: filters,
    validationSchema: Yup.object({
      website: Yup.string().nullable(),
      account_id: Yup.string().nullable(),
      handicapper: Yup.string().nullable(),
      start_date: Yup.date().nullable(),
      end_date: Yup.date().nullable(),
    }),
    onSubmit: (values) => {
      dispatch(setFilters(values));
    },
  });

  const handleClearFilter = () => {
    formik.resetForm();
    dispatch(refresh());
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-10 gap-1 items-center">
          <div className="col-span-1">
            <DropDown  
              name="website"
              value={formik.values.website}
              options={websites}
              optionValue="value"
              optionLabel="label"
              placeholder="Select Website"
              handleChange={(e) =>
                formik.setFieldValue("website", e.target.value)
              }
            />
          </div>
          <div className="col-span-1">
            <DropDown
              name="account_id"
              value={formik.values.account_id}
              options={accounts}
              optionValue="value"
              optionLabel="label"
              placeholder="Select Account"
              handleChange={(e) =>
                formik.setFieldValue("account_id", e.target.value)
              }
            />
          </div>
          <div className="col-span-1">
            <DropDown
              name="handicapper"
              value={formik.values.handicapper}
              options={handicappers}
              optionValue="title"
              optionLabel="title"
              placeholder="Select Handicapper"
              handleChange={(e) =>
                formik.setFieldValue("handicapper", e.target.value)
              }
            />
          </div>
          <div className="col-span-1 flex justify-center">
            <button
              type="submit"
              className="bg-primary px-2 py-1 mr-2 text-white rounded"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
            <button
              type="button"
              onClick={handleClearFilter}
              className="bg-primary px-2 py-1 text-white rounded"
            >
              <FontAwesomeIcon icon={faArrowRotateRight} />
            </button>
          </div>
          <div className="col-span-6 flex justify-end gap-2">
            <CalendarButton
              btnText="This week"
              handleSubmit={() => dispatch(setWeek(0))}
              selected={filters.week === 0}
            />
            <CalendarButton
              btnText="Last week"
              handleSubmit={() => dispatch(setWeek(1))}
              selected={filters.week === 1}
            />
            <CalendarButton
            btnText={"Two weeks ago"}
            handleSubmit={() => dispatch(setWeek(2))}
            selected={filters.week == 2}
          />
            <DropDown
              name="week"
              value={filters.week}
              options={weeks}
              optionValue="key"
              optionLabel="value"
              placeholder="Select Week"
              handleChange={(e) => dispatch(setWeek(e.target.value))}
            />
            <DateRangeCalendar
              onSelect={(e) => dispatch(setDateRange(e))}
              start_date={filters.start_date}
              end_date={filters.end_date}
            />
          </div>
        </div>
      </form>

      <hr className="border-black border-2 mt-2" />
      {loading ? (
        <TableLoader />
      ) : (
        <>
        <table className="table-auto w-full border-collapse mt-4 text-sm">
          <thead>
            <tr>
              <th>Website</th>
              <th className="whitespace-nowrap">Account ID</th>
              <th>Handicapper</th>
              <th>Team</th>
              <th>Spread</th>
              <th>Odds</th>
              <th>Total</th>
              <th>Result</th>
              <th>Status</th>
              <th>League</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {dailyFigures.map((item, index) => {
              return (
                <tr
                  key={index}
                  className="overflow-hidden whitespace-nowrap text-center border border-b-1 border-t-0 border-r-0 border-l-0"
                >
                  <td className="overflow-hidden p-2">
                    {item?.website?.slice(8)}
                  </td>
                  <td className="overflow-hidden p-2">{item?.account_id}</td>
                  <td className="overflow-hidden p-2">
                    <DropDown
                      name="handicapper"
                      value={item?.handicapper}
                      options={handicappers}
                      optionValue="title"
                      optionLabel="title"
                      placeholder="Select Handicapper"
                      handleChange={(e) => handleFieldChange(e, item.id)}
                    />
                  </td>
                  <td className="overflow-hidden p-2">
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      {item?.team_bet_on}
                    </span>
                  </td>
                  <td className="overflow-hidden p-2">
                    {item?.spread == "N/A" ? "" : item?.spread}
                  </td>
                  <td className="overflow-hidden p-2">
                    {item?.odds == "N/A" ? "" : item?.odds}
                  </td>
                  <td className="overflow-hidden p-2 ">
                    {item?.total == "N/A" || item?.total == "N\\A"
                      ? ""
                      : item?.total}
                  </td>
                  <td
                    className={`overflow-hidden p-2 font-bold text-right ${resultColor(
                      item
                    )}`}
                  >
                    {formatResult(item)}
                  </td>
                  <td className="overflow-hidden p-2">
                    <DropDown
                      name="status"
                      value={item?.status}
                      options={statuses}
                      optionValue="key"
                      optionLabel="value"
                      placeholder="Select Status"
                      handleChange={(e) => handleFieldChange(e, item.id)}
                    />
                  </td>
                  <td className="overflow-hidden p-2 text-sm">
                    {item?.sport}
                  </td>
                  <td className="overflow-hidden p-2">
                    {moment(item.accepted)?.format("YYYY-MM-DD hh:mm A")}
                  </td>
                  <td className="overflow-hidden p-2">
                    <Link
                      to={`/admin/daily-figure/${item.id}`}
                      type="button"
                      className="inline-flex items-center mr-2  rounded-md bg-orange-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Link>
                    <Link
                      to={`/admin/daily-figures/edit/${item.id}`}
                      type="button"
                      className="inline-flex  mr-2 items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination pagination={pagination} onPageChange={(page) => dispatch(setPage(page))} />
        </>
      )}
    </div>
  );
}
