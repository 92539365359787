import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/slices/authSlice";
import { ReactComponent as LoginIcon } from "../assets/icons/login.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
// RBAC
import { useAbility } from '@casl/react';
import { AbilityContext } from '../rbac/Can';
import { updateAbility } from '../rbac/Ability';


export default function Login() {
  const ability = useAbility(AbilityContext)
  const { user, token, loading, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Loggedin User', user);
    updateAbility(ability, user);

    if (token) navigate("/admin/dashboard", { replace: true });
  }, [token, navigate]);

  useEffect(() => {
    if (error) {
      // Handle error message array or single message
      if (error.message) {
        // If error.message exists, display it as a toast error
        toast.error(error.message);
      } else if (error.errors) {
        // If error.errors exists, loop through the fields and their messages
        for (const [field, messages] of Object.entries(error.errors)) {
          messages.forEach(message => toast.error(`${message}`));
        }
      }
    }
    
  }, [error]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLogin = (values) => {
      dispatch(loginUser(values));
  };

  return (
    <div className="grid grid-cols-6">
      <div className="col-span-2 flex flex-col gap-4 justify-center items-center">
        <h4 className="text-4xl">Welcome Back</h4>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form className="w-8/12">
              
              <div className="mb-4">
                <Field
                  name="email"
                  placeholder="Enter Email"
                  label="Email"
                  type="email"
                  className="form-control"
                />
                <ErrorMessage name="email" component="div" className="form-error" />
              </div>
              
              <div className="mb-4">
                <Field
                  name="password"
                  placeholder="Enter Password"
                  label="Password"
                  type="password"
                  className="form-control"
                />
                <ErrorMessage name="password" component="div" className="form-error" />
              </div>

              <button
              type="submit"
              className="btn-success"
              disabled={isSubmitting}
              >
              {isSubmitting ? "Loading..." : "Login"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex justify-center col-span-4 bg-primary h-screen">
        <LoginIcon className="w-8/12" />
      </div>
    </div>
  );
}
