import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// Components
import DropDown from "../../../components/form/DropDown";
// Store and Services
import { searchAction } from "../../../redux/slices/dailyFigureSearchSlice";
import { getHandicappers } from "../../../utils/helpers";
import {  
  getDailyFigures,
  getDailyFigureWebsites,
  getDailyFigureAccounts,
  updateDailyFigure,
} from "../../../services/dailyFigureService";

export default function DailyFigures() {
  const dispatch = useDispatch();

  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState("");

  const [usernames, setUsernames] = useState([]);
  const [username, setUsername] = useState("");

  const handicappers = getHandicappers();
  const [handicapper, setHandicapper] = useState([]);

  const filters = useSelector((state) => state.dailyFigureSearchSlice);
  const start_date_input = useRef(null);
  const end_date_input = useRef(null);
  if (start_date_input.current && filters.start_date)
    start_date_input.current.value = filters.start_date;
  if (end_date_input.current && filters.end_date)
    end_date_input.current.value = filters.end_date;

  const [figures, setFigures] = useState([]);
  useEffect(() => {
    getFigures(filters);
    
    getDailyFigureWebsites().then((res) => {
      setUsernames(res.data.data);
    });

    getDailyFigureAccounts().then((res) => {
      setAccounts(res.data.data);
    });

  }, []);

  function getFigures(params) {
    setAccount("");
    setUsername("");
    getDailyFigures({ ...params, type: "pending" }).then((res) => {
      setFigures(res?.data?.data);
      let selectedValues = res?.data?.data?.data.map((item) => {
        var values = {};
        ["id", "handicapper"].forEach(function (key) {
          if (item.hasOwnProperty(key)) {
            values[key] = item[key];
          }
        });
        return values;
      });
      setHandicapper(selectedValues);
    });
  }

  const handleFilterSubmit = () => {
    getFigures({ ...filters });
  };

  const handlePagination = (page) => {
    getFigures({ page: page, ...filters });
  };

  const handleHandicapperChange = (e, id) => {
    // setHandicapper(e.target.value)
    const data = {
      handicapper: e.target.value,
    };
    updateDailyFigure(data, id).then((res) => {});
  };

  const handleClearFilter = () => {
    getFigures({});
    dispatch(
      searchAction({
        website: "",
        account_id: "",
        end_date: "",
        start_date: "",
      })
    );
  };

  return (
    <div>
      <h1 className="text-lg font-bold underline">Pending Bets</h1>
      <table className="table-auto w-full border-collapse mt-8 text-sm">
        <thead>
          <tr>
            <th>Action</th>
            <th>Website</th>
            <th>Account ID</th>
            <th>Handicapper</th>
            <th>Team</th>
            <th>Spread</th>
            <th>Odds</th>
            <th>Total</th>
            <th>Risk</th>
            <th>To Win</th>
            <th>Sport</th>
            <th>Date</th>
            <th>Accepted At</th>
          </tr>
        </thead>
        <tbody>
          {figures?.data?.map((item, index) => {
            let handicapper_temp = handicapper?.find((temp) => {
              return temp.id == item.id;
            });
            let handicapper_value = handicapper_temp["value"];
            return (
              <tr
                key={index}
                className="overflow-hidden whitespace-nowrap text-center border border-b-1 border-t-0 border-r-0 border-l-0"
              >
                <td className="overflow-hidden p-2">
                  <Link
                    to={`/admin/daily-figures/edit/${item.id}`}
                    type="button"
                    className="inline-flex  mr-2 items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Edit
                  </Link>
                  <Link
                    to={`/admin/daily-figure/${item.id}`}
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Show
                  </Link>
                </td>
                <td className="overflow-hidden p-2">
                  {item?.website.slice(8)}
                </td>
                <td className="overflow-hidden p-2">{item?.account_id}</td>
                <td className="overflow-hidden p-2">
                  <DropDown
                    value={handicapper_temp["handicapper"]}
                    container_classes="w-full flex flex-col"
                    value_is_key={true}
                    handleChange={(e) =>
                      handleHandicapperChange(e, item.id)
                    }
                    label=""
                    name="handicapper"
                    options={handicappers}
                  />
                </td>
                <td className="overflow-hidden p-2">{item?.team_bet_on}</td>
                <td className="overflow-hidden p-2">{item?.spread}</td>
                <td className="overflow-hidden p-2">{item?.odds}</td>
                <td className="overflow-hidden p-2">{item?.total}</td>
                <td className="overflow-hidden p-2">{item?.risk}</td>
                <td className="overflow-hidden p-2">{item?.win}</td>
                <td className="overflow-hidden p-2">{item?.sport}</td>
                <td className="overflow-hidden p-2">
                  {(item?.time).endsWith("AM") || (item?.time).endsWith("PM")
                    ? item?.date + " " + item?.time
                    : moment(`${item?.date} ${item?.time}`).format(
                        "YYYY-MM-DD hh:mm A"
                      )}
                </td>
                <td className="overflow-hidden p-2">
                  {moment(`${item?.accepted}`).format("YYYY-MM-DD hh:mm A")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex gap-1 mt-2">
        {figures?.links?.map((item, index) => {
          let label = item.label;
          let page = item?.url?.split("?")[1];
          let page_numer = page?.split("=")[1];
          if (label.includes("Previous"))
            return (
              <div
                onClick={() => handlePagination(page_numer)}
                className={`p-2 border cursor-pointer 
                            ${
                              item.active
                                ? "bg-primary text-white"
                                : "hover:bg-gray-400"
                            }
                            ${item.url == null && "disabled"}`}
                key={index}
              >
                {label.split(" ")[1]}
              </div>
            );
          else if (label.includes("Next"))
            return (
              <div
                onClick={() => handlePagination(page_numer)}
                className={`p-2 border cursor-pointer 
                            ${
                              item.active
                                ? "bg-primary text-white"
                                : "hover:bg-gray-400"
                            }
                            ${!item.url && "disabled"}`}
                key={index}
              >
                {label.split(" ")[0]}
              </div>
            );
          else
            return (
              <div
                onClick={() => handlePagination(page_numer)}
                className={`px-4 py-2 border cursor-pointer 
                        ${
                          item.active
                            ? "bg-primary text-white "
                            : "hover:bg-gray-400"
                        }`}
                key={index}
              >
                {label}
              </div>
            );
        })}
      </div>
    </div>
  );
}
