import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { Can } from '../../../rbac/Can';
// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAdd,
    faEdit
  } from "@fortawesome/free-solid-svg-icons";
// Helpers
import { formatDateTime } from "../../../utils/helpers";
// Components
import TableLoader from "../../../components/TableLoader";
import Pagination from "../../../components/Pagination";
// Store and Services
import { fetchAgents, refresh, setFilters, setPage } from "../../../redux/slices/agentSlice";

function Agents() {
    const { agents, pagination, loading, filters, page } = useSelector((state) => state.agent);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAgents());
    }, [dispatch, filters, page]);

    const handleSearch = (values) => {
        dispatch(setFilters(values));
    };
    
    return (
        <>
        <div className="flex justify-between items-end">
            <h1 className="text-lg font-bold">Agents</h1>
            <Can I="create" a="agents">
                <Link to="/admin/agents/add" className="btn-primary">
                    <FontAwesomeIcon icon={faAdd} className="mr-2"/>
                    Add Agent
                </Link>
            </Can>   
        </div>

        <hr className="border-black border-2 mt-2" />

        <Formik
            initialValues={filters}
            onSubmit={handleSearch}
        >
            {({ resetForm, submitForm }) => (
                <Form className="flex gap-4 my-4">
                    <Field
                        name="name"
                        type="text"
                        placeholder="Name"
                        className="form-control"
                    />
                    <Field
                        name="email"
                        type="text"
                        placeholder="Email"
                        className="form-control"
                    />
                    <Field
                        name="username"
                        type="text"
                        placeholder="Username"
                        className="form-control"
                    />
                    <button type="submit" className="btn-primary">
                        Search
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            resetForm();
                            submitForm();
                        }}
                        className="btn-dark"
                    >
                        Reset
                    </button>
                </Form>
            )}
        </Formik>
        <hr className="border-black border-2 mt-2" />
        {loading ? (
            <TableLoader />    
        ) : (
            <>
            <table className="table-auto w-full border-collapse mt-8 text-sm">
                <thead className="table-header-group">
                    <tr>
                        <th>ID</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Website</th>
                        <th>Username</th>
                        <th>Password</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {agents?.map((item, index) => (
                        <tr
                            key={index}
                            className="rounded-md text-center border border-b-1 border-t-0 border-r-0 border-l-0 py-4 cursor-pointer hover:bg-gray-200"
                        >
                            <td className="overflow-hidden py-4">{item?.id}</td>
                            <td className="overflow-hidden py-4">{formatDateTime(item?.created_at)}</td>
                            <td className="overflow-hidden py-4">{formatDateTime(item?.updated_at)}</td>
                            <td className="overflow-hidden py-4">{item?.name}</td>
                            <td className="overflow-hidden py-4">{item?.email}</td>
                            <td className="overflow-hidden py-4">{item?.website}</td>
                            <td className="overflow-hidden py-4">{item?.username}</td>
                            <td className="overflow-hidden py-4">{item?.password}</td>
                            <td className="overflow-hidden py-4">
                            <Can I="update" a="agents">
                                <Link to={`/admin/agents/edit/${item?.id}`} className="btn-primary">
                                  <FontAwesomeIcon icon={faEdit} className="mr-2"/>
                                  Edit
                                </Link>
                            </Can>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination pagination={pagination} onPageChange={(page) => dispatch(setPage(page))} />
            </>
        )}
        </>
    );
}

export default Agents;
