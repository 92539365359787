import * as Yup from "yup";

/**
 * Domain validator
 * Ensures:
 * - No `http://` or `https://`
 * - Allows subdomains (`sub.example.com`)
 * - Requires a valid TLD (`.com`, `.net`, `.org`, etc.)
 */
Yup.addMethod(Yup.string, "domain", function (message = "Enter a valid domain (e.g., example.com)") {
  return this.matches(
    /^(?!:\/\/)(?!www\.)([a-zA-Z0-9-]+\.[a-zA-Z]{2,})$/,
    message
  );
});

// Export Yup so it includes the new method
export default Yup;