import apiClient from './apiClient';

export const getBettors = (params = {}) => {
  return apiClient.get(`/bettors`, {
    params: params,
  });
};

export const getBettor = (id) => {
  return apiClient.get(`/bettors/${id}`);
};

export const saveBettor = (data) => {
  return apiClient.post("/bettors", data);
};

export const updateBettor = (data, id) => {
  return apiClient.put(`/bettors/${id}`, data);
};

export const deleteBettor = (id) => {
  return apiClient.delete(`/bettors/${id}`);
};

export const getBettorsList = () => {
  return apiClient.get("/bettors/list");
};
