import moment from "moment";

/*
|======================================================
| Format Helper Functions
|======================================================
*/
export function formatDate(dateString) {
  return moment(dateString).format("MM/DD/YYYY");
}

export function formatDateTime(dateString) {
  return moment(dateString).format("MM/DD/YYYY hh:mm A");
}

export function formatDateForInput(dateString) {
  return moment(dateString).format("YYYY-MM-DD");
}

export function formatDateTimeForInput(dateString) {
  return moment(dateString).format("YYYY-MM-DDTHH:mm");
}

export function getTodayDateForInput() {
  return moment().format("YYYY-MM-DD");
}

export function getTodayDateTimeForInput() {
  return moment().format("YYYY-MM-DD HH:mm:ss");
}

export function formatMoney(amount, currency = "USD", locale = "en-US") {
  return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  }).format(amount);
}


/*
|======================================================
| Data Helper Functions
|======================================================
*/

export const getHandicappers = () => {
  return [
    { key: "None", value: "None" },
    { key: "MT", value: "MT" },
    { key: "Brazy", value: "Brazy" },
    { key: "DK", value: "DK" },
    { key: "Sunday", value: "Sunday" },
    { key: "Alexander", value: "Alexander" },
    { key: "Andy", value: "Andy" },
    { key: "Lucky", value: "Lucky" },
    { key: "Alex", value: "Alex" },
    { key: "Keith", value: "Keith" },
    { key: "Doc", value: "Doc" },
    { key: "Sheil", value: "Sheil" },
    { key: "Kevin", value: "Kevin" },
    { key: "Dean", value: "Dean" },
    { key: "Ray", value: "Ray" },
    { key: "B&M", value: "B&M" },
    { key: "Lewis", value: "Lewis" },
    { key: "Anthony", value: "Anthony" },
    { key: "Kim", value: "Kim" },
    { key: "Pablo", value: "Pablo" },
  ];
};

export const getWeeks = () => {
  let weeks = [];
  let temp = {};
  for (let i = 3; i <= 20; i++) {
    temp = {};
    temp.key = i;
    temp.value = `${i +" weeks ago"}`;
    weeks.push(temp);
  }

  return weeks;
};

export const getWeeksForChart = () => {
  let weeks = [];
  let temp = {};
  temp.key = 0;
  temp.value = "This week"
  weeks.push(temp);
  temp = {};
  temp.key = 1;
  temp.value = "Last week"
  weeks.push(temp);

  for (let i = 2; i <= 20; i++) {
    temp = {};
    temp.key = i;
    temp.value = `${i +" weeks ago"}`;
    weeks.push(temp);
  }

  return weeks;
};

export const getStatuses = () => {
  return [
    { key: "Win", value: "Win" },
    { key: "Loss", value: "Loss" },
    { key: "Push", value: "Push" },
  ];
};

export const getTeams = () => {
  return [
    { key: "San Francisco Giants", value: "San Francisco Giants" },
    { key: "San Diego Padres", value: "San Diego Padres" },
    { key: "Texas Rangers", value: "Texas Rangers" },
    { key: "Seattle Mariners", value: "Seattle Mariners" },
    { key: "Los Angeles Dodgers", value: "Los Angeles Dodgers" },
    { key: "Los Angeles Angels", value: "Los Angeles Angels" },
    { key: "Oakland Athletics", value: "Oakland Athletics" },
    { key: "Colorado Rockies", value: "Colorado Rockies" },
    { key: "Arizona Diamondbacks", value: "Arizona Diamondbacks" },
    { key: "Houston Astros", value: "Houston Astros" },
    { key: "Washington Nationals", value: "Washington Nationals" },
    { key: "Toronto Blue Jays", value: "Toronto Blue Jays" },
    { key: "Philadelphia Phillies", value: "Philadelphia Phillies" },
    { key: "Tampa Bay Rays", value: "Tampa Bay Rays" },
    { key: "New York Mets", value: "New York Mets" },
    { key: "New York Yankees", value: "New York Yankees" },
    { key: "Miami Marlins", value: "Miami Marlins" },
    { key: "Boston Red Sox", value: "Boston Red Sox" },
    { key: "Atlanta Braves", value: "Atlanta Braves" },
    { key: "Baltimore Orioles", value: "Baltimore Orioles" },
    { key: "St. Louis Cardinals", value: "St. Louis Cardinals" },
    { key: "Minnesota Twins", value: "Minnesota Twins" },
    { key: "Pittsburgh Pirates", value: "Pittsburgh Pirates" },
    { key: "Kansas City Royals", value: "Kansas City Royals" },
    { key: "Milwaukee Brewers", value: "Milwaukee Brewers" },
    { key: "Detroit Tigers", value: "Detroit Tigers" },
    { key: "Cincinnati Reds", value: "Cincinnati Reds" },
    { key: "Cleveland Guardians", value: "Cleveland Guardians" },
    { key: "Chicago Cubs", value: "Chicago Cubs" },
    { key: "Dallas Cowboys", value: "Dallas Cowboys" },
    { key: "Seattle Seahawks", value: "Seattle Seahawks" },
    { key: "Chicago White Sox", value: "Chicago White Sox" },
  ];
};

export const getHandicapper = () => {
  return [
    { key: "MT", value: "MT" },
    { key: "Brazy", value: "Brazy" },
    { key: "DK", value: "DK" },
    { key: "Sunday", value: "Sunday" },
    { key: "Alexander", value: "Alexander" },
    { key: "Andy", value: "Andy" },
    { key: "Lucky", value: "Lucky" },
    { key: "Alex S", value: "Alex S" },
    { key: "Keith", value: "Keith" },
    { key: "Doc", value: "Doc" },
    { key: "Sheil", value: "Sheil" },
    { key: "Kevin", value: "Kevin" },
    { key: "Dean", value: "Dean" },
    { key: "Chad", value: "Chad" },
    { key: "Ray", value: "Ray" },
    { key: "B&M", value: "B&M" },
    { key: "Lewis", value: "Lewis" },
    { key: "Anthony", value: "Anthony" },
    { key: "Kim", value: "Kim" },
    { key: "Pablo", value: "Pablo" },
  ];
};

/*
|======================================================
| Other Helper Functions
|======================================================
*/

export const formatResult = (item) => {
  return item?.status == "Win"
    ? "+ $" + parseFloat(cleanAmount(item?.win)).toFixed(2)
    : item?.status == "Push"
    ? "$" + parseFloat(0).toFixed(2)
    : "- $" + parseFloat(cleanAmount(item?.risk)).toFixed(2);
};

export const resultColor = (item) => {
  return item?.status == "Win"
    ? "text-green-500"
    : item?.status == "Push"
    ? "text-gray-500"
    : "text-red-500";
};

export const cleanAmount = (value) => {
  // return value.trim().replace(",", "").slice(1);
  if (value == null) {
    return 0; // or return a default value or handle it accordingly
  }
  
  // Your existing logic for cleaning the amount
  const cleanedValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
  return isNaN(cleanedValue) ? 0 : cleanedValue;
};
