import React, { useState, useEffect } from "react";
import packageJson from "../package.json";

const buildDateGreaterThan = (latestDate, currentDate) => {
  return latestDate > currentDate;
};

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch("/meta.json")
        .then((response) => {
          // Check if the response is OK (status code 200-299)
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
          }
          // Check if the content type is JSON
          const contentType = response.headers.get("content-type");
          if (!contentType || !contentType.includes("application/json")) {
            throw new TypeError("Response is not JSON");
          }
          return response.json();
        })
        .then((meta) => {
          const latestVersionDate = meta.buildDateTime;
          const currentVersionDate = packageJson.buildDateTime;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching meta.json:", error);
          // Handle the error gracefully, e.g., set isLatestBuildDate to true
          setIsLatestBuildDate(true);
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} shouldRerender={true} /> : null}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;