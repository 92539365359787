import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
// Store and Services
import { getManager, updateManager } from "../../../services/managerService";

function EditManager() {
    const navigate = useNavigate(); // Hook for navigation
    const { id } = useParams(); // Get ID from URL
    const [manager, setManager] = useState({name: '', email: ''})

    // Fetch manager details when component mounts
    useEffect(() => {
        console.log('id', id);
        const fetchManager = async () => {
            console.log('fetchManager');
            try {
                const response = await getManager(id);

                console.log('response', response.data);
                if (response.data.status === "success") {
                    setManager(response.data.manager);
                }
            } catch (error) {
                toast.error("Failed to load manager data.");
            }
        };

        if (id) {
            fetchManager();
        }
    }, [id]);
    
    // Validation schema for the form
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
        password: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required("Password is required"),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
    });

    // Initial values for the form
    const initialValues = {
        name: manager.name || "",
        email: manager.email || "",
        password: "",
        confirm_password: "",
    };

    // Form submission handler
    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        // console.log('form: ', values);
        
        await updateManager(values, id).then((res) => {
            if (res.data.status === "success") {
                toast.success(res.data.message);
                // Redirect after submission
                navigate("/admin/managers"); 
            }
            }).catch((error) => {
            if (error.response && error.response.status === 422) {
                // Validation errors
                const validationErrors = error.response.data.errors;
                for (const [field, messages] of Object.entries(validationErrors)) {
                // Show error messages for each field
                messages.forEach(message => toast.error(`${message}`));
                }
            } else {
                // Other errors
                toast.error('An error occurred. Please try again.');
            }
        
        });

        setSubmitting(false); // Reset isSubmitting to false after submission
    };
    return (
        <>
        <div className="flex justify-between items-end">
            <h1 className="text-lg font-bold">Edit Manager</h1>
        </div>

        <hr className="border-black border-2 mt-2" />

        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-2">
                    {/* Name Field */}
                    <div className="col-span-1">
                    <label htmlFor="name" className="form-label required">Name</label>
                    <Field
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder="Type name"
                    />
                    <ErrorMessage name="name" component="div" className="form-error" />
                    </div>

                    {/* Email Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="email" className="form-label required">Email</label>
                    <Field
                        type="text"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Enter email"
                    />
                    <ErrorMessage name="email" component="div" className="form-error" />
                    </div>

                </div>

                <div className="grid gap-4 mb-4 grid-cols-2">

                    {/* Password Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="password" className="form-label required">Password</label>
                    <Field
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        placeholder="Enter password"
                    />
                    <ErrorMessage name="password" component="div" className="form-error" />
                    </div>

                    {/* Confirm Password Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="confirm_password" className="form-label required">Confirm Password</label>
                    <Field
                        type="password"
                        name="confirm_password"
                        id="confirm_password"
                        className="form-control"
                        placeholder="Enter confirm password"
                    />
                    <ErrorMessage name="confirm_password" component="div" className="form-error" />
                    </div>

                </div>

                {/* Submit Button */}
                <div className="w-full flex justify-end">
                    <button
                    type="submit"
                    className="btn-primary"
                    disabled={isSubmitting}
                    >
                    {isSubmitting ? "Updating..." : "Edit Manager"}
                    </button>
                </div>
                </Form>
            )}
            </Formik>
        </>
    );
}

export default EditManager;
