import ability from '../rbac/Ability';
import { UnauthorizedError } from "./Error";

const Authorization = ({ action, subject, children}) => {

  return ability.can(action, subject) ? children : <UnauthorizedError />;

};

export default Authorization;
