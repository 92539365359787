import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/authSlice";
import account from "./slices/accountSlice";
import handicapper from "./slices/handicapperSlice";
import dailyFigure from "./slices/dailyFigureSlice";
import dailyFigureSearch from "./slices/dailyFigureSearchSlice";
import manager from "./slices/managerSlice";
import bettor from "./slices/bettorSlice";
import agent from "./slices/agentSlice";
import bet from "./slices/betSlice";

const store = configureStore({
  reducer: {
    auth: auth,
    account: account,
    handicapper: handicapper,
    dailyFigure: dailyFigure,
    dailyFigureSearch: dailyFigureSearch,
    manager: manager,
    bettor: bettor,
    agent: agent,
    bet: bet,
  },
});

export default store;
