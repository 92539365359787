import {AbilityBuilder} from '@casl/ability'
import {defineAbility} from '@casl/ability'

export function updateAbility(ability, user) {
  const { can, rules } = new AbilityBuilder();

  can('manage', 'auth');
  can('view', 'dashboard');
  
  // Admin gets full access
  if (user?.roles?.includes('admin')) {
    can('manage', 'all');
  }

  // Loop through permissions dynamically
  user?.permissions?.forEach((permission) => {
    const [subject, action] = permission.split('.'); // Splits "posts.view" → ["posts", "view"]

    if (subject && action) {
      can(action, subject); // Capitalize subject (e.g., "Posts")
    }
  });

  ability.update(rules)
}

export default defineAbility((can, cannot) => {
  can('manage', 'auth');
  can('view', 'dashboard');

  try {
    const user = JSON.parse(localStorage.getItem("user"));
    
    // Admin gets full access
    if (user?.roles?.includes('admin')) {
      can('manage', 'all');
    }

    // Loop through permissions dynamically
    user?.permissions?.forEach((permission) => {
      const [subject, action] = permission.split('.'); // Splits "posts.view" → ["posts", "view"]

      if (subject && action) {
        can(action, subject); // Capitalize subject (e.g., "Posts")
      }
    });
  } catch (error) {
    console.error("Error parsing user data:", error);
    return;
  }
  
})