
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faChartColumn,
  faMagic,
  faAdd,
  faSignOut,
  faUsers,
  faFileInvoice,
  faFlag,
  faPeopleRoof,
  faUserTie,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Can } from '../rbac/Can';

export default function Sidebar({ isExpanded }) {
  const appName = process.env.REACT_APP_NAME;
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setUserName(user.name);
    }
  }, []);

  // Sidebar options defined within the component
  const sidebarOptions = [
    {
      icon: faHouse,
      title: "Dashboard",
      url: "/admin/dashboard",
      subject: "dashboard",
      action: "view",
    },
    {
      icon: faChartColumn,
      title: "Figures",
      url: "/admin/daily-figures",
      subject: "daily-figures",
      action: "view",
    },
    {
      icon: faFileInvoice,
      title: "Accounts",
      url: "/admin/accounts",
      subject: "accounts",
      action: "view",
    },
    {
      icon: faUsers,
      title: "Handicappers",
      url: "/admin/handicappers",
      subject: "handicappers",
      action: "view",
    },
    {
      icon: faAdd,
      title: "Add New Bet",
      url: "/admin/daily-figure/add",
      subject: "daily-figures",
      action: "create",
    },
    {
      icon: faUserTie,
      title: "Managers",
      url: "/admin/managers",
      subject: "managers",
      action: "view",
    },
    {
      icon: faPeopleGroup,
      title: "Bettors",
      url: "/admin/bettors",
      subject: "bettors",
      action: "view",
    },
    {
      icon: faPeopleRoof,
      title: "Agents",
      url: "/admin/agents",
      subject: "agents",
      action: "view",
    },
    {
      icon: faFlag,
      title: "Bets",
      url: "/admin/bets",
      subject: "bets",
      action: "view",
    },
    {
      icon: faSignOut,
      title: "Logout",
      url: "/logout",
      subject: "auth",
      action: "logout",
    },
    
  ];

  return (
    <motion.div
      animate={{
        width: isExpanded ? "180px" : "50px",
        transition: { ease: "easeInOut", duration: 0.5 },
      }}
      className="bg-primary overflow-hidden text-white h-full min-h-screen p-4"
    >
      <div className="flex justify-between items-center w-full">
        <motion.h1
          animate={{
            opacity: isExpanded ? "1" : "0",
            transition: { ease: "easeInOut", duration: 2, delay: 0.1 },
            transitionEnd: { display: isExpanded ? "block" : "none" },
          }}
        >
          <Link to="/admin/daily-figures">
                <svg className="logo-text cursor-pointer" viewBox="0 0 480 110">
                    <text y="50">{appName}</text>
                </svg>
          </Link>
        </motion.h1>
        
      </div>
      {userName && (
          <div className="mt-4 text-sm font-semibold text-white">
            Welcome, {userName}
          </div>
        )}
      <ul className="mt-8">
        {sidebarOptions.map((option, index) => (
          <div
            key={index}
            className={`${
              currentPath.includes(option.url) ? "bg-white text-black font-bold" : ""
            }`}
          >
            <Can I={option.action} a={option.subject}>
              <Link
                to={option.url}
                className="flex justify-between items-center gap-1 cursor-pointer hover:bg-blue-800 hover:text-white p-1"
              >
                <FontAwesomeIcon
                  icon={option.icon}
                  size={isExpanded ? "lg" : "xl"}
                />
                {isExpanded && <p className="text-left">{option.title}</p>}
              </Link>
              <hr
              className={`${isExpanded ? "border-gray-400" : "border-primary"}`}
            />
            </Can>
           
          </div>
        ))}
      </ul>
    </motion.div>
  );
}
