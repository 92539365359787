import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
// Helpers
import { formatDateForInput } from "../../../utils/helpers";
// Components
import Select from "../../../components/form/Select";
// Store and Services
import { getBettorsList } from "../../../services/bettorService";
import { getAgentsList } from "../../../services/agentService";
import { getBet, updateBet } from "../../../services/betService";

function EditBet() {
    const navigate = useNavigate(); // Hook for navigation
    const { id } = useParams(); // Get ID from URL
    const [bet, setBet] = useState({});
    const [bettors, setBettors] = useState([]);
    const [agents, setAgents] = useState([]);
    const [outcomes, setOutcomes] = useState([
        {label: 'WIN', value: 'WIN'}, 
        {label: 'LOSS', value: 'LOSS'},
        {label: 'PUSH', value: 'PUSH'},
    ]);
    const [playTypes, setPlayTypes] = useState([
        {label: 'Spread', value: 'Spread'}, 
        {label: 'ML', value: 'ML'},
        {label: 'Under', value: 'Under'},
        {label: 'Over', value: 'Over'},
    ]);
    const [playCategories, setPlayCategories] = useState([{label: 'Delay', value: 'Delay'}, {label: 'Live', value: 'Live'}]);
    

    useEffect(() => {

        getBettorsList().then((res) => {
            setBettors(res.data.data);
        });
    
        getAgentsList().then((res) => {
            setAgents(res.data.data);
        });
    
    }, []);

    // Fetch bet details when component mounts
    useEffect(() => {
        console.log('id', id);
        const fetchBet = async () => {
            console.log('fetchBet');
            try {
                const response = await getBet(id);

                console.log('response', response.data);
                if (response.data.status === "success") {
                    setBet(response.data.bet);
                }
            } catch (error) {
                toast.error("Failed to load bet data.");
            }
        };

        if (id) {
            fetchBet();
        }
    }, [id]);
    
    // Validation schema for the form
    const validationSchema = Yup.object().shape({
        bet_date: Yup.date().required("Bet date is required"),
        bettor_id: Yup.number().required("Bettor is required"),
        agent_id: Yup.number().required("Agent is required"),
        league: Yup.string(),
        play_details: Yup.string().required("Play details are required"),
        session: Yup.string(),
        line: Yup.string().required("Line is required"),
        odds: Yup.string().required("Odds are required"),
        play_type: Yup.string(),
        outcome: Yup.string().required("Outcome is required"),
        play_category: Yup.string().required("Play category is required"),
        skin: Yup.string(),
        amount: Yup.number().required("Amount is required").positive("Amount must be positive"),
    });

    // Initial values for the form
    const initialValues = {
        bet_date: bet?.bet_date ? formatDateForInput(bet?.bet_date) : "",
        bettor_id: bet?.bettor_id || "",
        agent_id: bet?.agent_id || "",
        league: bet?.league || "",
        play_details: bet?.play_details || "",
        session: bet?.session || "",
        line: bet?.line || "",
        odds: bet?.odds || "",
        play_type: bet?.play_type || "",
        outcome: bet?.outcome || "",
        play_category: bet?.play_category || "",
        skin: bet?.skin || "",
        amount: bet?.amount || "",
    };
    

    // Form submission handler
    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        // console.log('form: ', values);
        
        await updateBet(values, id).then((res) => {
            if (res.data.status === "success") {
                toast.success(res.data.message);
                // Redirect after submission
                navigate("/admin/bets"); 
            }
            }).catch((error) => {
            if (error.response && error.response.status === 422) {
                // Validation errors
                const validationErrors = error.response.data.errors;
                for (const [field, messages] of Object.entries(validationErrors)) {
                // Show error messages for each field
                messages.forEach(message => toast.error(`${message}`));
                }
            } else {
                // Other errors
                toast.error('An error occurred. Please try again.');
            }
        
        });

        setSubmitting(false); // Reset isSubmitting to false after submission
    };
    return (
        <>
        <div className="flex justify-between items-end">
            <h1 className="text-lg font-bold">Edit Bet</h1>
        </div>

        <hr className="border-black border-2 mt-2" />

        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            >
            {({ isSubmitting }) => (
                <Form className="p-4 md:p-5">

                <div className="grid gap-4 mb-4 grid-cols-2">
                    
                    {/* Date Field */}
                    <div className="col-span-1">
                    <label htmlFor="bet_date" className="form-label required">Date</label>
                    <Field
                        type="date"
                        name="bet_date"
                        id="bet_date"
                        className="form-control"
                        placeholder="Select date"
                    />
                    <ErrorMessage name="bet_date" component="div" className="form-error" />
                    </div>      

                </div>

                <div className="grid gap-4 mb-4 grid-cols-2">

                    {/* Bettor Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="bettor_id" className="form-label required">Bettor</label>
                    <Select 
                        name="bettor_id" 
                        options={bettors}
                        optionValue="id"
                        optionLabel="name"
                        placeholder="Select bettor"
                    />
                    <ErrorMessage name="bettor_id" component="div" className="form-error" />
                    </div>

                    {/* Agent Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="agent_id" className="form-label required">Agent</label>
                    <Select 
                        name="agent_id" 
                        options={agents}
                        optionValue="id"
                        optionLabel="name"
                        placeholder="Select agents"
                    />
                    <ErrorMessage name="agent_id" component="div" className="form-error" />
                    </div>

                </div>

                <div className="grid gap-4 mb-4 grid-cols-2">

                    {/* League Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="league" className="form-label">League</label>
                    <Field
                        type="text"
                        name="league"
                        id="league"
                        className="form-control"
                        placeholder="Enter league"
                    />
                    <ErrorMessage name="league" component="div" className="form-error" />
                    </div>

                    {/* Play Details Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="play_details" className="form-label required">Play Details</label>
                    <Field
                        type="text"
                        name="play_details"
                        id="play_details"
                        className="form-control"
                        placeholder="Enter play details"
                    />
                    <ErrorMessage name="play_details" component="div" className="form-error" />
                    </div>

                </div>

                <div className="grid gap-4 mb-4 grid-cols-2">

                    {/* Session Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="session" className="form-label">Session</label>
                    <Field
                        type="text"
                        name="session"
                        id="session"
                        className="form-control"
                        placeholder="Enter session"
                    />
                    <ErrorMessage name="session" component="div" className="form-error" />
                    </div>

                    {/* Line Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="line" className="form-label required">Line</label>
                    <Field
                        type="text"
                        name="line"
                        id="line"
                        className="form-control"
                        placeholder="Enter line"
                    />
                    <ErrorMessage name="line" component="div" className="form-error" />
                    </div>

                </div>

                <div className="grid gap-4 mb-4 grid-cols-2">

                    {/* Odds Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="odds" className="form-label required">Odds</label>
                    <Field
                        type="text"
                        name="odds"
                        id="odds"
                        className="form-control"
                        placeholder="Enter odds"
                    />
                    <ErrorMessage name="odds" component="div" className="form-error" />
                    </div>

                    {/* Play Type Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="play_type" className="form-label">Play Type</label>
                    <Select 
                        name="play_type" 
                        options={playTypes}
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Select play type"
                    />
                    <ErrorMessage name="play_type" component="div" className="form-error" />
                    </div>

                </div>

                 <div className="grid gap-4 mb-4 grid-cols-2">

                    {/* Outcome Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="outcome" className="form-label required">Outcome</label>
                    <Select 
                        name="outcome" 
                        options={outcomes}
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Select outcome"
                    />
                    <ErrorMessage name="outcome" component="div" className="form-error" />
                    </div>

                    {/* Play Category Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="play_category" className="form-label required">Play Category</label>
                    <Select 
                        name="play_category" 
                        options={playCategories}
                        optionValue="value"
                        optionLabel="label"
                        placeholder="Select play category"
                    />
                    <ErrorMessage name="play_category" component="div" className="form-error" />
                    </div>

                </div>

                <div className="grid gap-4 mb-4 grid-cols-2">

                    {/* Skin Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="skin" className="form-label">Skin</label>
                    <Field
                        type="text"
                        name="skin"
                        id="skin"
                        className="form-control"
                        placeholder="Enter skin"
                    />
                    <ErrorMessage name="skin" component="div" className="form-error" />
                    </div>

                    {/* Amount Field */}
                    <div className="col-span-2 sm:col-span-1">
                    <label htmlFor="amount" className="form-label required">Amount</label>
                    <Field
                        type="number"
                        name="amount"
                        id="amount"
                        className="form-control"
                        placeholder="Enter amount"
                    />
                    <ErrorMessage name="amount" component="div" className="form-error" />
                    </div>

                </div>

                {/* Submit Button */}
                <div className="w-full flex justify-end">
                    <button
                    type="submit"
                    className="btn-primary"
                    disabled={isSubmitting}
                    >
                    {isSubmitting ? "Updating..." : "Edit Bet"}
                    </button>
                </div>
                </Form>
            )}
            </Formik>
        </>
    );
}

export default EditBet;
